import React from 'react';
import { aboutStyle } from '../assets/jss/pages/aboutStyle';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { DefaultHeader } from '../components/Header/Header';
import { Parallax } from '../components/Parallax/Parallax';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { graphql, PageRendererProps } from 'gatsby';
import { CategoryNode } from '../interfaces/contentful';
import { createCategoryTree } from '../helpers/treeHelper';
import backgroundImage from '../assets/img/Luds_BikeBrugRhenen3.jpg';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles(aboutStyle);

const AboutPage: React.FC<ThankYouPageProps> = ({ data }) => {
  const { categories } = data;

  const categoryTree = createCategoryTree(categories);

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.blogPostPage}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'breadcrumbs.about' })}</title>
      </Helmet>
      <DefaultHeader categoryTree={categoryTree} />

      <Parallax image={backgroundImage} className={classes.parallax}>
        {/*<div className={classes.container}>*/}
        {/*  <Breadcrumbs>*/}
        {/*    <Typography color="secondary">*/}
        {/*      <FormattedMessage id="breadcrumbs.about" defaultMessage="About us page" />*/}
        {/*    </Typography>*/}
        {/*  </Breadcrumbs>*/}
        {/*</div>*/}
      </Parallax>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Typography variant="h4" component="h2" gutterBottom>
              <strong>
                <FormattedMessage id="about.title" defaultMessage="Our story" />
              </strong>
            </Typography>
            <section
              style={{
                margin: '5px auto',
                position: 'relative',
                width: '100%',
                minHeight: '695px',
              }}
            >
              <StaticImage
                className={classes.shapeImage}
                src="../assets/img/FietsEnMeSmal.jpg"
                alt="Op de fiets in de tuin"
              />
              <br />
              <br />
              <br />
              <br />
              <Typography style={{ textAlign: 'right' }} variant="h5" component="h3" gutterBottom>
                <FormattedMessage id="about.text.intro" defaultMessage="About text intro" />
              </Typography>
              <br />
              <br />

              <Typography style={{ textAlign: 'right' }} variant="h5" component="h3" gutterBottom>
                <FormattedMessage id="about.text.start" defaultMessage="About text start" />
              </Typography>
              <br />
              <br />

              <Typography style={{ textAlign: 'right' }} variant="h5" component="h3" gutterBottom>
                <FormattedMessage id="about.text.mission" defaultMessage="About text mission" />
              </Typography>
            </section>

            <br />
            <Typography style={{ textAlign: 'center' }} variant="h3" component="h3" gutterBottom>
              <FormattedMessage id="about.text.customers" defaultMessage="About text customers" />
            </Typography>
            <br />

            <section
              style={{
                marginTop: '0px',
                position: 'relative',
                width: '100%',
                minHeight: '680px',
                margin: '5px auto',
              }}
            >
              <StaticImage
                className={classes.shapeImage2}
                src="../assets/img/FietsEnMeMicrogreens.jpg"
                alt="Op de fiets naast Microgreens in Ede"
              />
              <br />
              <br />
              <Typography style={{ textAlign: 'left' }} variant="h5" component="h3" gutterBottom>
                <FormattedMessage id="about.text.suppliers" defaultMessage="About text suppliers" />
              </Typography>
              <br />
              <br />
              <Typography style={{ textAlign: 'left' }} variant="h5" component="h3" gutterBottom>
                <FormattedMessage id="about.text.systems" defaultMessage="About text systems" />
              </Typography>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutPage;

export const thankYouQuery = graphql`
  query {
    categories: allContentfulCategory {
      edges {
        node {
          slug
          title
          contentful_id
          parentCategory {
            slug
            contentful_id
          }
          regionalproduct {
            contentful_id
          }
        }
      }
    }
  }
`;

interface ThankYouPageProps extends PageRendererProps {
  data: {
    categories: {
      edges: { node: CategoryNode }[];
    };
  };
}
